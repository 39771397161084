// PhotoEdit.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';

const PhotoEdit = () => {
  const { id } = useParams();
  const history = useNavigate();
  const [title, setTitle] = useState('');
  const [file, setFile] = useState(null);

  useEffect(() => {
    axios.get(`http://localhost:5000/photos/${id}`)
      .then(response => {
        setTitle(response.data.title);
      })
      .catch(error => console.error(error));
  }, [id]);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpdate = () => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('title', title);

    axios.put(`http://localhost:5000/photos/${id}`, formData)
      .then(() => history.push('/'))
      .catch(error => console.error(error));
  };

  return (
    <div>
      <h1>Edit Photo</h1>
      <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Title" />
      <input type="file" onChange={handleFileChange} />
      <button onClick={handleUpdate}>Update</button>
    </div>
  );
};

export default PhotoEdit;
