import React, { useState } from 'react';
import axios from 'axios';
import './reg.css';

const RegistrationForm = () => {
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    birth_date: '',
    gender: '',
    contact_number: '',
    email: '',
    address: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
//    axios.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';
    axios.post('https://cors-anywhere.herokuapp.com/http://88.222.245.36:3001/patients', formData)
      .then(response => {
        alert('Patient registered successfully');
      })
      .catch(error => {
        alert('Patient registration failed');
        console.error('There was an error registering the patient!', error);        
        

      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        First Name:
        <input type="text" name="first_name" value={formData.first_name} onChange={handleChange} required />
      </label>
      <label>
        Last Name:
        <input type="text" name="last_name" value={formData.last_name} onChange={handleChange} required />
      </label> 
      <label>
        Birth Date:
        <input type="date" name="birth_date" value={formData.birth_date} onChange={handleChange} required />
      </label>
      <label>
        Gender:
        <select name="gender" value={formData.gender} onChange={handleChange} required>
          <option value="">Select</option>
          <option value="Male">Male</option>
          <option value="Female">Female</option>
          <option value="Other">Other</option>
        </select>
      </label>
      <label>
        Contact Number:
        <input type="text" name="contact_number" value={formData.contact_number} onChange={handleChange} />
      </label>
      <label>
        Email:
        <input type="email" name="email" value={formData.email} onChange={handleChange} />
      </label>
      <label>
        Address:
        <input type="text" name="address" value={formData.address} onChange={handleChange} />
      </label>
      <button type="submit">Register</button>
    </form>
  );
};

export default RegistrationForm;
