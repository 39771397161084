import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div>
      <h1>Home Page</h1>
      <p>Atharva Chest Clinic Allergy And Asthma Centre is highly relevant to individuals seeking specialized care for allergies, asthma, and respiratory conditions..</p>
      <nav>
        <ul>
          <li><Link to="/about-us">About Us</Link></li>
          <li><Link to="/gallery">Gallery</Link></li>
          <li><Link to="/login">Login</Link></li>
        </ul>
      </nav>
    </div>
  );
};

export default Home;