import React from 'react';
import { Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import LoginIcon from '@mui/icons-material/Login';
import './Navbar.css';
import { AppRegistration, PhotoAlbum } from '@mui/icons-material';
import RegistrationForm from './registration/RegistrationForm';

const Navbar = () => {
  return (
    <nav>
      <ul>
        <li>
          <Link to="/" className="nav-link">
            <HomeIcon /> Home
          </Link>
        </li>
        <li>
          <Link to="/about-us" className="nav-link">
            <InfoIcon /> About Us
          </Link>
        </li>
        <li>
          <Link to="/gallery" className="nav-link">
            <PhotoLibraryIcon /> Gallery
          </Link>
        </li>
        <li>
          <Link to="/login" className="nav-link">
            <LoginIcon /> Login
          </Link>
        </li>
        <li>
          <Link to="/photo" className="nav-link">
            <PhotoAlbum /> Photo
          </Link>
        </li>
        <li>
          <Link to="/registration" className="nav-link"> 
          <AppRegistration/>
            Registration
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
