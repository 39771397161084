import React, { useEffect, useState } from 'react';
import axios from 'axios';

const PhotoList = () => {
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    const fetchPhotos = async () => {
      const response = await axios.get('http://localhost:5000/api/photos');
      setPhotos(response.data);
    };
    fetchPhotos();
  }, []);

  const handleDelete = async (id) => {
    await axios.delete(`http://localhost:5000/api/photos/${id}`);
    setPhotos(photos.filter(photo => photo.id !== id));
  };

  return (
    <div>
      {photos.map(photo => (
        <div key={photo.id}>
          <img src={photo.url} alt={photo.description} width="100" />
          <p>{photo.description}</p>
          <button onClick={() => handleDelete(photo.id)}>Delete</button>
        </div>
      ))}
    </div>
  );
};

export default PhotoList;
