import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Home';
import AboutUs from './AboutUs';
import Gallery from './Gallery';
import Login from './Login';
import Navbar from './Navbar';
import Dashboard from './Dashboard';
import Test from './Test';
import Photo from './Photo';
import PhotoList from './PhotoList';
import PhotoEdit from './PhotoEdit';
import PhotoUpload from './PhotoUpload';
import RegistrationForm from './registration/RegistrationForm';



const App = () => {
  return (
    
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/login" element={<Test />} />
          <Route path="/registration" element={< RegistrationForm />} />
         
         
          
          {/* <Route path="/photo" element={<Photo />} /> */}
          <Route path="/photolist"  element={<PhotoList />} />
          <Route path="/upload" element={<PhotoUpload />} />
          <Route path="/edit/:id" element={<PhotoEdit /> } />      
            
        </Routes>
      </div>
    </Router>
    
  );
};

export default App;
