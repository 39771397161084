import React from 'react';
import { Link } from 'react-router-dom';

const Gallery = () => {
  return (
    <div>
      <h1>Gallery</h1>
      <p>Check out our gallery.</p>
      <nav>
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/about-us">About Us</Link></li>
          <li><Link to="/login">Login</Link></li>
        </ul>
      </nav>
    </div>
  );
};

export default Gallery;